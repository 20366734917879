@import '~antd/dist/antd.css';

body {
    height: 100vh;
    margin: 0px;
    font-family: 'Mulish', sans-serif;
    background: #e7e7e7;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 600 !important;
}

.adjustHeight {
    height: 95vh !important;
}

.link {
    font-size: 14px;
    cursor: pointer;
    color: #015c9c;
}

.input-label-error {
    margin-bottom: 0px;
    color: #ff4d4f;
}

.listViewDiv {
    display: flex;
    overflow: scroll;
    padding-top: 20px;
    height: calc(100vh - 120px);
    justify-content: center;
}

.bodyWidth {
    max-width: 450px;
    width: calc(100vw - 40px );
}

.cardOverlay {
    padding: 10px 20px;
    max-width: 450px;
    background-color: #fff;
    border-radius: 15px !important;
    transition: all .2s ease-in-out;
    width: calc(100vw - 40px );
}

.MuiAccordionSummary-content {
    align-items: center;
}

.letterSpace_2 {
    letter-spacing: 1px;
}

.footer {
    bottom: 10px;
    line-height: 3;
    position: fixed;
    max-width: 450px;
    text-align: center;
    width: calc(100vw - 40px );
}

.normal_button {
    border: none !important;
    background: #015c9c !important;
    color: white !important;
}

.newCardbutton {
    height: 48px;
    width: 100%;
    border-radius: 15px;
    background: #015c9c !important;
    color: white !important;
    display: flex;
    border: none !important;
    align-items: center;
    font-family: 'Raleway';
    justify-content: space-between;
    transition: all .2s ease-in-out;
}

.newCardbutton:hover , .cardOverlay:hover {
    transform: scale(1.04);
}

.ant-form-item-label label {
    font-family: 'Raleway';
    color: lightgray !important;
    letter-spacing: 0.7px !important;
}

.ant-form-item-control-input-content input , .ant-input-number-input-wrap input {
    height: 42px;
    border-radius: 2px;
}

.cardImageInput {
    top: 46px;
    width: auto;
    right: 10px;
    height: 20px;
    position: absolute;
}

.cancelText {
    position: fixed;
    max-width: 450px;
    border-radius: 15px;
    width: calc(100vw - 40px );
    bottom: 20px;
    text-align: center;
    color: white;
    cursor: pointer;
    font-size: 14px;
}

.ant-form-item {
    margin-bottom: 24px;
}

.ant-alert {
    height: 48px;
    border-radius: 5px;
}

.SuccessScreen {
    height: 90%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.SuccessScreen img {
    max-width: 70px;
}

.SuccessScreen h5 {
    color: #03609e;
    font-size: 21px;
    font-weight: bold;
    letter-spacing: .5px;
    font-family: 'poppins';
    margin: 16px 0px 0px 0px;
}

.SuccessScreen .reference {
    color: #03609e;
    letter-spacing: .5px;
    padding-bottom: 8px;
    margin: 16px 0px 0px 0px;
    font-family: 'poppins';
    font-weight: 200;
    font-size: 12px;
    border-bottom: 1px solid grey;
}

.SuccessScreen p {
    color: #03609e;
    letter-spacing: .5px;
    padding-bottom: 8px;
    font-family: 'poppins';
    margin: 16px 0px 0px 0px;
}

.SuccessScreen h6 {
    color: #03609e;
    font-size: 26px;
    letter-spacing: .5px;
    padding-bottom: 8px;
    font-family: 'poppins';
}

.SuccessScreen .link {
    font-size: 14px;
    color: cornflowerblue;
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    cursor: pointer;
    text-decoration: underline;
}

.card_img {
    width: auto;
    height: auto;
    max-width: 30px;
}

.nametext {
    color: 'black';
    padding-left: 20px;
}

.h35{
    height: 40px;
}

.ant-radio-wrapper {
    margin-right: 0px;
}

.buttonView {
    background: white;
    width: 140px;
}

.buttonViewActive {
    color: white;
    background-color: #03609e;
}

.buttonView p {
    padding: 8px 24px;
    font-weight: 900;
    cursor: pointer;
}

.buttonView:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.buttonView:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.ant-select-selector {
    height: 42px !important;
    padding: 6px 16px !important;
}

#rapyd-checkout-frame {
    margin-left: -24px;
}

.ant-modal {
    width: 100vw !important;
}

.NMI #ccnumber ,.NMI #ccexp ,.NMI #cvv , #ccnumber_field , #ccexp_field , #cvv_field , #cname_field ,
.NMI #checkname ,.NMI #checkaba ,.NMI #checkaccount
{
  opacity: 1;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0px;
  background-color: rgb(255, 255, 255);
  background-image: none;
  transition: all 0.3s ease 0s;
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  padding: 7px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  height: 42px;
  border-radius: 2px;
  margin-bottom: -7px;
}

.loader_div {
    z-index: 99995;
    height: 100vh;
    position: absolute;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0000008a;
}

.loader_div img {
    width: 50px;
}

.ant-form-item-label {
    padding-bottom:  0px !important;
}

.ant-form-item-label  label {
    color: black !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
}

.ant-input:disabled {
    color: black;
    background-color: #c5c5c5;
}